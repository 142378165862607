import {
  AssignedToMe,
  Business,
  CreatedByMe,
  DelayedTasks,
  Holidays,
  MyTasks,
  Newsletter,
  Notification,
  ThisMonthTasks,
  ThisWeekTask,
  TodayTasks,
  UpcomingTasks,
} from '../../Constant';

export const taskData = [
  {
    id: 1,
    activeTab: '1',
    title: CreatedByMe,
  },
  {
    id: 2,
    activeTab: '2',
    title: TodayTasks,
  },
  {
    id: 3,
    activeTab: '3',
    title: DelayedTasks,
  },
  {
    id: 4,
    activeTab: '4',
    title: UpcomingTasks,
  },
  {
    id: 5,
    activeTab: '5',
    title: ThisWeekTask,
  },
  {
    id: 6,
    activeTab: '6',
    title: ThisMonthTasks,
  },
  {
    id: 7,
    activeTab: '7',
    title: AssignedToMe,
  },
  {
    id: 8,
    activeTab: '8',
    title: MyTasks,
  },
];

export const tagData = [
  {
    id: 1,
    activeTab: '9',
    title: Notification,
  },
  {
    id: 2,
    activeTab: '10',
    title: Newsletter,
  },
  {
    id: 3,
    activeTab: '11',
    title: Business,
  },
  {
    id: 4,
    activeTab: '12',
    title: Holidays,
  },
];
